import React from "react";

const ServiceContentThree = () => {
  return (
    <section className="dg__service__area bg--white padding--hor bg__color--5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="service__chart">
              <div className="thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/service.png"}
                  alt="service images"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 offset-xl-1 md__mt--40 sm__mt--40">
            <div className="service__inner">
              <h3 className="title__with__border">A Whole new level experience.</h3>
              <p>
              Try the taste of simplicity and security by using Tezmoon Wallet, you can use it anywhere without worrying about the safety of your money.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceContentThree;
