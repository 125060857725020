import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import ServiceContentTwo from "../components/service-contents/ServiceContentTwo";
import ServiceContentThree from "../components/service-contents/ServiceContentThree";
import ServiceFeature from "../containers/service-contents/ServiceFeature";

const TezMoonWallet = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Tezmoon | TezMoon Wallet</title>
        <meta
          name="description"
          content="TezMoon Wallet page of TezMoon Website."
        />
      </MetaTags>
      <LayoutTwo theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="Tezmoon Wallet" />
        {/* service content */}
        <ServiceContentTwo />
        {/* service features */}
        <ServiceFeature />
        {/* service content */}
        <ServiceContentThree />
      </LayoutTwo>
    </Fragment>
  );
};

export default TezMoonWallet;
