import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";

const AboutContentTwo = () => {
  return (
    <div className="dg__service__area bg--white padding--hor bg__color--5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="service__chart">
              <div className="thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/service.png"}
                  alt="chart"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 offset-xl-1 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
            <div className="service__inner">
              {/* section title */}
              <SectionTitleFour title="A Whole new level experience." />
              <p>
              Try the taste of simplicity and security by using Tezmoon Wallet, you can use it anywhere without worrying about the safety of your money.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContentTwo;
