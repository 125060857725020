import React from "react";

const CurrencyCalculation = () => {
  return (
    <div className="dg__calculation__area bg__color--6 poss--relative section-padding--xlg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="dg__calculation">
              <h2>A simple calculator to know today’s prices</h2>
              <p>
                We provide an internal calculator to make currency Converter
                easier for you.
              </p>
              <div className="calculate__box">
                <p>Currency Calculator.</p>
                <input
                  className="btc-conterter"
                  type="text"
                  placeholder={1}
                  data-current-rate="9205.68"
                />
                <span className="text-white">=</span>
                <input
                  className="btc-coverter-output"
                  type="text"
                  placeholder="8003.23"
                />
                <div className="dg__select__option">
                  <select>
                    <option>Bitcoin</option>
                    <option>Ethereum</option>
                    <option>Binance</option>
                    <option>XRP</option>
                    <option>Litecoin</option>
                  </select>
                  <span>&nbsp;&nbsp;</span>
                  <select className="space--left">
                    <option>USD</option>
                    <option>CAD</option>
                    <option>EUR</option>
                    <option>GBP</option>
                    <option>EGP</option>
                    <option>CHF</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <img
              src={process.env.PUBLIC_URL + "/images/about/1.png"}
              alt="images"
              className="img img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyCalculation;
