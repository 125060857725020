import React from "react";
import SectionTitleSix from "../../components/ui/section-titles/SectionTitleSix";
import serviceData from "../../data/service-lists/service-list-one.json";
import ServiceListSingle from "../../components/service-contents/ServiceListSingle";

const ServiceList = () => {
  return (
    <section className="dg__service__area pb--140 pt--80"  style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg/13.jpg"})`,
      backgroundRepeat: `no-repeat`,
      backgroundSize:`cover`
    }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* section title */}
            <SectionTitleSix
              title="Our Wallet features"
              text="A secure place to store and trade your Tezmoon. Some features include Simplex integration, dark mode, contacts list, hold to cancel."
            />
          </div>
        </div>
      </div>
      <div className="space__dec">
        <div className="custom__service__width">
          {serviceData &&
            serviceData.map((single, key) => {
              return <ServiceListSingle data={single} key={key} />;
            })}
        </div>
      </div>
    </section>
  );
};

export default ServiceList;
